import {
  colorFuegoStatsBrown,
  colorFuegoStatsOrange,
  colorFuegoStatsYellow,
} from "../../lib/colors";

export const IconFuegoStats: React.FC<
  React.SVGProps<SVGSVGElement> & {
    simple?: boolean;
    size?: number;
    color?: string;
  }
> = ({ simple, size = 60, color, ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={size}
    width={size}
    viewBox="0 0 400 400"
    {...rest}
  >
    {simple ? null : (
      <>
        {" "}
        <path
          d="M187.12,370.38l182.22-182.23s0-.02,0-.02l-182.25,182.25s.02,0,.02,0Z"
          style={{ fill: colorFuegoStatsOrange }}
        />
        <path
          d="M200,31.2C106.21,31.2,30.17,107.24,30.17,201.03c0,.14,0,.28,0,.41L200.41,31.21c-.14,0-.28,0-.41,0Z"
          style={{ fill: colorFuegoStatsOrange }}
        />
        <path
          d="M179.55,369.63l189.05-189.05c-7.77-64.72-51.93-118.23-111.43-139.5L40.05,258.21c21.27,59.5,74.78,103.66,139.5,111.43Z"
          style={{ fill: colorFuegoStatsOrange }}
        />
        <path
          d="M30.38,209.54s0,.05,0,.08L208.58,31.42s-.05,0-.08,0L30.38,209.54Z"
          style={{ fill: colorFuegoStatsOrange }}
        />
        <path
          d="M361.22,254.53l-107.72,107.72c50.76-16.83,90.89-56.97,107.72-107.72Z"
          style={{ fill: colorFuegoStatsOrange }}
        />
        <path
          d="M253.5,362.26l107.72-107.72c1.84-5.54,3.39-11.2,4.66-16.98l-129.36,129.36c5.77-1.27,11.44-2.82,16.98-4.66Z"
          style={{ fill: colorFuegoStatsBrown }}
        />
        <path
          d="M200,370.86c12.54,0,24.75-1.37,36.52-3.95l129.36-129.36c2.58-11.76,3.95-23.98,3.95-36.52,0-.85-.02-1.7-.03-2.56l-172.35,172.35c.85.01,1.7.03,2.56.03Z"
          style={{ fill: colorFuegoStatsYellow }}
        />
        <path
          d="M192.19,370.68l112-112c.09-.29.25-.56.48-.78l55.88-55.88c.23-.23.5-.38.78-.48l8.31-8.31c-.08-1.7-.17-3.39-.3-5.07l-182.22,182.23c1.68.13,3.37.22,5.07.3Z"
          style={{ fill: colorFuegoStatsYellow }}
        />
        <path
          d="M187.1,370.38l182.25-182.25c-.19-2.53-.44-5.05-.75-7.55l-189.05,189.05c2.5.3,5.02.55,7.55.75Z"
          style={{ fill: colorFuegoStatsBrown }}
        />
        <path
          d="M363.37,202.01c.78.78.78,2.05,0,2.83l-55.88,55.88c-.39.39-.9.59-1.41.59s-1.02-.2-1.41-.59c-.55-.55-.71-1.35-.48-2.04l-112,112c1.74.08,3.5.13,5.25.15l172.35-172.35c-.03-1.76-.07-3.51-.15-5.25l-8.31,8.31c.7-.23,1.49-.08,2.04.48Z"
          style={{ fill: "#fff" }}
        />
        <path
          d="M40.05,258.21L257.17,41.08c-3.06-1.09-6.16-2.1-9.3-3.02L37.02,248.91c.92,3.14,1.93,6.24,3.02,9.3Z"
          style={{ fill: colorFuegoStatsBrown }}
        />
        <path
          d="M30.71,214.65L213.62,31.75c-1.67-.13-3.35-.25-5.04-.33L30.39,209.61c.08,1.69.2,3.37.33,5.04Z"
          style={{ fill: colorFuegoStatsYellow }}
        />
        <path
          d="M37.02,248.91L247.87,38.06c-9.46-2.77-19.25-4.75-29.32-5.84L31.18,219.59c1.09,10.06,3.07,19.86,5.84,29.32Z"
          style={{ fill: colorFuegoStatsYellow }}
        />
        <path
          d="M200.41,31.21L30.18,201.45c0,2.71.07,5.41.21,8.09L208.5,31.42c-2.68-.13-5.38-.2-8.09-.21Z"
          style={{ fill: colorFuegoStatsBrown }}
        />
      </>
    )}
    <path
      d="M31.18,219.59L218.56,32.22c-1.64-.18-3.28-.34-4.94-.47L30.71,214.65c.13,1.65.29,3.3.47,4.94Z"
      style={{ fill: "#fff" }}
    />
    <path
      d="M304.67,260.72c.39.39.9.59,1.41.59s1.02-.2,1.41-.59l55.88-55.88c.78-.78.78-2.05,0-2.83-.55-.55-1.35-.71-2.04-.48-.29.09-.56.25-.78.48l-55.88,55.88c-.23.23-.38.5-.48.78-.23.7-.08,1.49.48,2.04Z"
      style={{ fill: "#fff" }}
    />
    <path
      d="M382.27,124.02c-9.97-23.56-24.23-44.71-42.39-62.87-18.16-18.16-39.31-32.42-62.87-42.39-24.41-10.32-50.32-15.56-77.01-15.56-3.67,0-7.32.11-10.95.31-24.83-3.96-45.96-2.58-60.98,1.42-15.45,4.11-23.3,12.96-25.23,23.75-15.59,8.79-29.91,19.66-42.72,32.47-18.16,18.16-32.42,39.31-42.38,62.87-10.32,24.41-15.56,50.32-15.56,77.01s5.23,52.61,15.56,77.01c9.97,23.56,24.23,44.71,42.38,62.87,18.16,18.16,39.31,32.42,62.87,42.39,24.41,10.32,50.32,15.56,77.01,15.56s52.61-5.23,77.01-15.56c23.56-9.97,44.71-24.23,62.87-42.39,18.16-18.16,32.42-39.31,42.39-62.87,10.32-24.41,15.56-50.32,15.56-77.01s-5.23-52.61-15.56-77.01ZM200,370.86c-93.79,0-169.83-76.04-169.83-169.83,0-60.1,31.22-112.9,78.32-143.09,7.21,12.96,19.67,20.25,32.11,28.31,38.31,21.74,78.87,34.58,122.38,32.7,16.44-1.7,35.06-5.94,45.07-25.14,3.62-6.95,3.39-15.39-.55-24.25,38.04,31.14,62.33,78.47,62.33,131.47,0,93.79-76.04,169.83-169.83,169.83Z"
      style={{ fill: simple && color ? color : colorFuegoStatsBrown }}
    />
    <path
      d="M299.95,88.3c-4.51,12.93-15.47,18.04-26.55,20.2,7.45-2.98,11.91-9.49,12.4-17.57,1.18-19.67-17.85-31.13-37.43-41.66-30.32-16.28-62.26-23.32-96.12-24.8-14.21.22-29.86,5-35.3,18.61-1.64,4.1-.82,8.54.35,13.37-6.53-7.35-8.09-13.05-6.16-23.54,2.69-14.63,19.88-26.87,68.94-20.94,62.62,7.56,129.58,48.53,119.88,76.34Z"
      style={{ fill: "#fff" }}
    />
    <path
      d="M276.93,96.54c-9.94,19.72-53.48,11.08-77.51,4.82-25.16-6.56-48.67-19.82-63.11-30.16-4.55-3.26-14.23-15.49-10.98-26.47,4.31-14.57,29.94-12.2,45.13-10.54-8.08,1.07-13.05,4.8-14.73,11.59-3.3,13.3,15.03,26.69,30.23,32.02,6.68,2.34,3.08-1.72.54-2.98-8.34-5.53-22.64-13.42-21.76-23.39.43-3,1.85-8.44,12.42-10.2,12.82-2.69,23.55.2,37.22,3.62,12.22,3.06,26.13,10.92,34.35,21.04,3.02,3.72,5.78,9.39,1.13,14.74-8.42,9.69-34.91,3.68-34.91,3.68-3.25-.54-4.95,1.64-.14,2.71,20.73,4.6,31.65,4.88,38.35-.64,8.21-7.31,6.22-17.6,1.95-22.81,12.02,7.05,28.78,19.17,21.82,32.98Z"
      style={{ fill: "#fff" }}
    />
    <path
      d="M233.66,67.85c-1.55,4.84-6.26,5-10.51,5.15-13.49-.6-32.85-7.07-36.32-18.88-1.14-5.71,6.03-11.01,16.37-8.02,2.76.8,1.8,1.32.98,1.39-5.26.4-7.85-.32-9.41,4.56-1.25,3.8,7.57,10.3,12.27,11.99,6.33,2.29,16.41,3.47,18.54-.49,2.23-4.13-3.62-8.97-7.44-11.36-1.42-1.12-.43-1.71,3.91.58,6,3.16,13.64,8.68,11.6,15.08Z"
      style={{ fill: simple && color ? color : colorFuegoStatsBrown }}
    />
    <path
      d="M295.72,272.88c-1.22-29.22-13.8-51.9-33.31-73.8,1.38,13.6-8.81,28.95-19.94,31.92-.43-61.07-24.77-77.79-53.67-88.97,13.62,47.48-8.1,54.11-20.55,65.74-10.53,9.84-14.36,22.15-14,37.38-9.62-12.21-13.85-26.84-8.15-43.5-7.81,8.78-32.57,25.65-32.57,72.05,0,27.86,20.8,61.93,59.9,69.29-14.35-16.84-17.85-32.76-7.09-51.53,8.69-15.15,36.07-12.79,23.53-53.21,29.1,16.96,45.21,34.84,25.2,71.44,10.65-.4,18.64-7.91,20.18-19.49,17.72,15.61,11.52,34.03,1.03,51.32,42.7-8.83,60.39-45.76,59.44-68.64Z"
      style={{ fill: "#fff" }}
    />
    <path
      d="M188.8,142.03c28.9,11.18,53.24,27.9,53.67,88.97,11.13-2.98,21.32-18.33,19.94-31.92,19.51,21.9,32.09,44.58,33.31,73.8.95,22.89-16.75,59.81-59.44,68.64,10.49-17.29,16.7-35.71-1.03-51.32-1.54,11.57-9.53,19.08-20.18,19.49,20.01-36.6,3.91-54.48-25.2-71.44,12.54,40.42-14.84,38.07-23.53,53.21-10.77,18.77-7.27,34.69,7.09,51.53-39.11-7.36-59.9-41.43-59.9-69.29,0-46.4,24.76-63.27,32.57-72.05-5.7,16.65-1.47,31.29,8.15,43.5-.36-15.23,3.47-27.54,14-37.38,12.45-11.64,34.17-18.26,20.55-65.74M175.15,127.1l4.99,17.41c4.58,15.98,5.22,27.78,1.95,36.07-2.64,6.67-7.79,10.71-13.24,14.99-2.25,1.76-4.57,3.59-6.75,5.62-3.76,3.52-6.89,7.38-9.38,11.63.3-2.71.93-5.46,1.89-8.26l14.48-42.28-29.72,33.38c-.85.96-2.12,2.19-3.59,3.63-5.23,5.11-13.13,12.82-19.66,24.76-7.7,14.08-11.61,30.79-11.61,49.65,0,31.74,23.57,69.91,67.24,78.13l25.05,4.72-16.54-19.4c-12.64-14.83-14.41-26.77-6.13-41.21,1.6-2.8,4.87-5.05,8.66-7.65,5.52-3.8,12.39-8.53,16.28-17.04,1.87-4.09,2.86-8.68,2.98-13.82,5.2,4.38,8.64,8.6,10.65,12.95,5.58,12.06-.5,25.75-5.55,34.99l-7.61,13.91,15.84-.6c8.73-.33,16.27-4.13,21.48-10.31,1.6,6.68-.41,15.45-8.31,28.49l-10.72,17.67,20.24-4.19c47.77-9.88,67.71-51.46,66.61-77.83-1.19-28.63-12.17-53.12-35.58-79.41l-18.47-20.74,2.8,27.63c.42,4.15-.83,8.63-2.93,12.5-2.1-20.98-7.7-37.45-16.98-50.02-11.62-15.75-27.5-23.41-41.51-28.83l-16.89-6.54h0Z"
      style={{ fill: simple && color ? color : colorFuegoStatsBrown }}
    />
  </svg>
);
