"use client";

import "/public/fuegostats.css";
import "../theme.scss";
import { Archivo } from "next/font/google"; // https://fonts.google.com/variablefonts
import { ReactQueryClientProvider } from "../components/ReactQueryClientProvider";
import React, { useEffect, useState } from "react";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import BugsnagPerformance from "@bugsnag/browser-performance";
import Script from "next/script";
import ModalWhatsNewAlert from "../components/modals/ModalWhatsNewAlert";
const NEXT_PUBLIC_GOOGLE_ANALYTICS = process.env.NEXT_PUBLIC_MEASUREMENT_ID;

Bugsnag.start({
  apiKey: "1802886be0eea9d7831620390feeadef",
  plugins: [new BugsnagPluginReact()],
});
BugsnagPerformance.start({ apiKey: "1802886be0eea9d7831620390feeadef" });

const bodyFont = Archivo({
  weight: "400",
  subsets: ["latin"],
});

const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

export default function RootLayout({ children }: React.PropsWithChildren<{}>) {
  const [splashState, setSplashState] = useState<
    "visible" | "fading" | "hidden"
  >("visible");

  useEffect(() => {
    const startTime = Date.now();

    const waitForLoad = new Promise<void>((resolve) => {
      if (document.readyState === "complete") {
        resolve();
      } else {
        window.addEventListener("load", () => resolve(), { once: true });
      }
    });

    waitForLoad.then(() => {
      const elapsed = Date.now() - startTime;
      // show splash screen for at least xxx ms
      const delay = elapsed < 350 ? 350 - elapsed : 0;
      setTimeout(() => {
        setSplashState("fading");
        setTimeout(
          () => {
            setSplashState("hidden");
          },
          // fade-out transition duration
          300
        );
      }, delay);
    });
  }, []);

  return (
    <ReactQueryClientProvider>
      <html lang="en" /*data-bs-theme="dark"*/>
        <head>
          <title>Fuego Stats</title>
          <Script
            src={`https://www.googletagmanager.com/gtag/js?id=${NEXT_PUBLIC_GOOGLE_ANALYTICS}`}
            strategy="afterInteractive"
          />
          <Script id="google-analytics" strategy="afterInteractive">
            {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${NEXT_PUBLIC_GOOGLE_ANALYTICS}');
          `}
          </Script>
        </head>
        <body className={bodyFont.className}>
          {splashState !== "hidden" && (
            <div
              id="splash-screen"
              className={splashState === "fading" ? "splash-hidden" : ""}>
              <img
                src="/svg/logo.svg"
                alt="Loading Fuego Stats..."
                className="splash-logo"
              />
            </div>
          )}
          <ErrorBoundary>{children}</ErrorBoundary>
        </body>
      </html>
      <ModalWhatsNewAlert />
    </ReactQueryClientProvider>
  );
}
